import React, { Component } from 'react'
import _ from 'lodash'
import { Select, Button, Table, Spin, Empty, Radio, Icon } from 'antd'
import { getEducations } from 'services/eduService'
import { getGroups } from 'services/groupService'
import { getRating } from 'services/ratingService'
import LineChart from './LineChart'

const { Option } = Select;

const columns = [
    { title: 'Место', dataIndex: 'place', key: 'place' },
    { title: 'Имя', dataIndex: 'student.firstName', key: 'student.firstName' },
    { title: 'Фамилия', dataIndex: 'student.lastName', key: 'student.lastName' },
    { title: 'Отчество', dataIndex: 'student.middleName', key: 'student.middleName' },
    { title: 'День Рождения', dataIndex: 'student.birthDay', key: 'student.birthDay' },
    { title: 'Пол', dataIndex: 'student.gender', key: 'student.gender' },
    { title: 'Класс', dataIndex: 'groupName', key: 'groupName' },
    { title: 'Заработанные Баллы', dataIndex: 'earnedPoints', key: 'earnedPoints' },
    { title: 'Потраченное Время', dataIndex: 'spentTime', key: 'spentTime' },
]

class Page extends Component {
    state = {
        classList: [
            {id: 1, name: '1 класс'},
            {id: 2, name: '2 класс'},
            {id: 3, name: '3 класс'},
            {id: 4, name: '4 класс'},
            {id: 5, name: '5 класс'},
            {id: 6, name: '6 класс'},
            {id: 7, name: '7 класс'},
            {id: 8, name: '8 класс'},
            {id: 9, name: '9 класс'},
        ],
        eduList: [],
        eduValue: undefined,
        groupsList: [],
        ratingList: [],
        groupValue: undefined,
        classValue: undefined,
        iconLoading: false,
        isLoading: true,
        tabValue: 'chart'
    }

    async componentDidMount() {
        const res = await getEducations()
        
        console.log('EDUs', res.data.data)
        this.setState({ isLoading: false })
        if (res && res.status === 200) {
            //* TEMP CODE HERE
            const tempData = res.data.data
            tempData[1]['kind'] = 1 //

            this.setState({ eduList: tempData })
        }
    }

    handleEduChange = async value => {
        const { eduList, classList } = this.state

        this.setState({ isLoading: true })

        const eduKind = eduList.find(item => item.id === value).kind
        let newGroupsList = []
        
        if (eduKind === 0) {
            newGroupsList = classList
        }
        else {
            const res = await getGroups(value)
            console.log('Groups', res.data.data)
            
            if (res && res.status === 200) {
                newGroupsList = res.data.data
            }
        }

        this.setState({
            eduValue: value,
            groupsList: newGroupsList,
            groupValue: undefined,
            ratingList: [],
            isLoading: false
        })
    }

    handleGroupChange = async value => {
        this.setState({ isLoading: true })

        const res = await getRating(value, this.state.eduValue)
        console.log('Ratings', res.data.data)
        
        if (res && res.status === 200) 
            this.setState({ groupValue: value, ratingList: this.modifyData(res.data.data) })
        
        this.setState({ isLoading: false })
    }

    modifyData(data) {
        const modifiedArray = data.map(item => {
            return item = {
                key: item.student.id,
                ...item,
                student: {
                    ...item.student,
                    gender: item.student.gender === 0 ? 'М' : 'Ж'
                },
            }
        })

        const sortedArray = _.sortBy(modifiedArray, [function(o) { return o.earnedPoints }]).reverse()

        let counter = 1
        sortedArray.forEach(element => {
            element.place = counter
            counter++
        });

        return sortedArray
    }

    enterIconLoading = () => {
        this.setState({ iconLoading: true })
    }

    onTabChange = e => {
        this.setState({ tabValue: e.target.value });
    }

    render() {
        const { tabValue } = this.state

        const educations = this.state.eduList.map(item => 
            <Option key={item.id} value={item.id}>{item.fullName}</Option>
        )

        const groups = this.state.groupsList.map(item => 
            <Option key={item.id} value={item.id}>{item.name}</Option>
        )

        return (
            <React.Fragment>
                <Spin spinning={this.state.isLoading}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <div className="form-control" style={{marginRight: 30}}>
                                <p style={{marginBottom: 5}}><label>Выберите учебное заведение:</label></p>
                                <Select
                                    showSearch
                                    value={this.state.eduValue}
                                    placeholder={this.props.placeholder}
                                    onChange={this.handleEduChange}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    notFoundContent={null}
                                >
                                    {educations}
                                </Select>
                            </div>
                            <div className="form-control" style={{marginRight: 30}}>
                                <p style={{marginBottom: 5}}><label>Выберите класс:</label></p>
                                <Select
                                    showSearch
                                    value={this.state.groupValue}
                                    placeholder={this.props.placeholder}
                                    onChange={this.handleGroupChange}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    notFoundContent={null}
                                >
                                    {groups}
                                </Select>
                            </div>
                            <Button
                                type="primary"
                                loading={this.state.iconLoading}
                                onClick={this.enterIconLoading}
                                style={{alignSelf: 'flex-end'}}
                            >
                                Найти
                            </Button>
                        </div>
                        <Radio.Group value={tabValue} onChange={this.onTabChange}>
                            <Radio.Button value="chart"><Icon type="pie-chart" /></Radio.Button>
                            <Radio.Button value="table"><Icon type="unordered-list" /></Radio.Button>
                        </Radio.Group>
                    </div>

                    {this.state.ratingList.length > 0
                        ? (
                            this.state.tabValue === 'table' 
                                ? <Table style={{marginTop: 30}}
                                        columns={columns} dataSource={this.state.ratingList} />
                                : <LineChart style={{marginTop: 30}} data={this.state.ratingList} 
                                        mainTitle="Количество заработанных балов за все время (Топ 10)" />
                        )
                        : this.state.groupValue !== undefined && <Empty style={{marginTop: 100}} description="Данных не найдено" />
                    }
                </Spin>
            </React.Fragment>
        )
    }
}

export default Page
