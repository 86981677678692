import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import { secondsToHms } from 'utilities/timeConverter'

class LineChart extends Component {
    state = {
        chartBarData: {
            labels: [],
            datasets: [
                {
                    label: 'Products',
                    data: [],
                    backgroundColor: '#FFF3F3'
                }
            ],
            displayTitle: true,
            displayLegend: true,
            legendPosition: 'right',
        }
    }

    componentDidMount() {
        this.getChartData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.getChartData()
        }
    }


    getChartData() {
        const { data } = this.props

        if (data.length !== 0) {
            console.log('chart data', data)
            const firstTenData = []
            for (let index = 0; index < 10; index++) {
                firstTenData.push(data[index])
            }
            console.log('updated chart data', firstTenData)
            // get all labels
            const labels = []
            firstTenData.map(m => labels.push(m.student.firstName + ' ' + m.student.lastName))
            // get all values
            const datasetValues = []
            firstTenData.map(m => datasetValues.push(m.earnedPoints))
            // Generate colors for bars
            const datasetColors = []
            for (let index = 0; index < datasetValues.length; index++) {
                datasetColors.push(`rgb(${[1, 2, 3].map(x => Math.random() * 256 | 0)}, .6)`)
            }

            const datasets = [
                {
                    data: datasetValues,
                    backgroundColor: datasetColors,
                    borderColor: datasetColors,
                    // fill: false,
                    // lineTension: 0
                }
            ]
            let chartBarData = { ...this.state.chartBarData, labels, datasets }
            this.setState({ chartBarData })
        }
    }


    render() {
        const isTime = this.props.isTime
        return (
            <React.Fragment>
                <div style={this.props.style}>
                    <Line
                        data={this.state.chartBarData}
                        options={{
                            title: {
                                display: true,
                                text: this.props.mainTitle,
                                fontSize: 20,
                                padding: 30
                            },
                            legend: {
                                display: this.state.displayLegend,
                                position: this.state.legendPosition,
                                fontSize: 25,
                                labels: {
                                    fontFamily: "Arial"
                                }
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    label: function (tooltipItem) {
                                        return isTime ? secondsToHms(tooltipItem.value) : tooltipItem.value
                                    }
                                }
                            },
                            hover: {
                                mode: 'nearest',
                                intersect: true
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: "#E5E6E8",
                                        display: false,
                                    },
                                    ticks: {
                                        fontSize: 14,
                                        padding: 30,
                                        fontFamily: "Arial"
                                    }
                                }],
                                yAxes: [{
                                    gridLines: {
                                        zeroLineColor: '#E5E6E8'
                                    },
                                    ticks: {
                                        fontSize: 18,
                                        padding: 30,
                                        fontFamily: "Arial",
                                        callback: function (value) {
                                            return isTime ? secondsToHms(value) : value
                                        }
                                    }
                                }]
                            }
                        }}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default LineChart