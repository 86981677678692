import React, { Component } from 'react'
import { Layout, Menu, Icon, Button } from 'antd'
import auth from 'services/authService'
import Page from './Page'

const { Header, Sider, Content } = Layout

class MainContent extends Component {
    state = {
      collapsed: false,
    };
  
    toggle = () => {
      this.setState({
        collapsed: !this.state.collapsed,
      });
    };

    handleLogout = () => {
      auth.logout()
      this.props.history.replace('/login')
    }

    render() {
        return (
            <Layout>

              <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                <div className="logo" />
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                  <Menu.Item key="1">
                    <Icon type="user" />
                    <span>nav 1</span>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Icon type="video-camera" />
                    <span>nav 2</span>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Icon type="upload" />
                    <span>nav 3</span>
                  </Menu.Item>
                </Menu>
              </Sider>

              <Layout>
                <Header style={{ background: '#fff', padding: '0 16px' }} 
                    className="d-flex justify-content-between align-items-center">
                  <Icon
                    className="trigger"
                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={this.toggle}
                  />
                  <Button type="primary" onClick={this.handleLogout}>
                    Logout
                  </Button>
                </Header>
                <Content
                  style={{
                    margin: '24px 16px',
                    padding: 24,
                    background: '#fff',
                    minHeight: 280,
                  }}
                >
                  <Page />
                </Content>
              </Layout>

            </Layout>
        )
    }
}

export default MainContent
