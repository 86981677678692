export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? ' ч ' : ' ч ') : '';
    var mDisplay = m > 0 ? m + (m === 1 ? ' м ' : ' м ') : '';
    var sDisplay = s > 0 ? s + (s === 1 ? ' с' : ' с') : '';
    return hDisplay + mDisplay + sDisplay;
}
// var hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
// var mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
// var sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';