import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/edu'

export async function getEducations() {
    return await http.get(apiEndpoint)
}

export default {
    getEducations
}