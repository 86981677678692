import axios from 'axios'
import { toast } from 'react-toastify'
import auth from './authService'

axios.interceptors.response.use(null,
    error => {
        const expectedError = error.response
            && error.response.status >= 400
            && error.response.status < 500
        
        try {
            if (error.response.status === 401) {
                auth.refreshToken()
            }
        } catch (error) {

        }

        if (!expectedError) {
            console.log(error)
            toast.error('Network error.', error)
        }

        if (expectedError) {
            if (error.response.data.message === 'Данный токен не найден!') return auth.logout()
            console.log(error.response.data.message)
            toast.error(error.response.data.message)
        }

        return Promise.reject(error)
    }
)

function setJwt(jwt) {
    axios.defaults.headers.common['Authorization'] = jwt
    axios.defaults.headers.common['X-Edu'] = 1
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setJwt
}