import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import MainContent from './components/MainContent'
import ProtectedRoute from './components/common/ProtectedRoute'
import Login from './components/Login'

import './App.css'
import 'react-toastify/dist/ReactToastify.css'

class App extends Component {

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <Switch>
          <Route path="/login" component={Login} />
					<ProtectedRoute path="/" component={MainContent} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default withRouter(App)