import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/auth'
const tokenKey = 'token'
const tokenRefreshKey = 'refreshToken'

// set your local headers
http.setJwt(getJwt())

export async function login(data) {
    const res = await http.post(apiEndpoint + '/login', data)

    return updateJwtAndCookies(res)
}

export function logout() {
    Cookies.remove(tokenKey)
    Cookies.remove(tokenRefreshKey)
}

export async function refreshToken() {
    const refreshKey = Cookies.get(tokenRefreshKey)

    if (refreshKey) {
        const response = await http.post(apiEndpoint + '/refresh', { 'refreshToken': refreshKey })

        console.log(response)
        if (response === undefined) {
            Cookies.remove(tokenKey)
            Cookies.remove(tokenRefreshKey)
            return window.location = '/login'
        }

        const { data: res } = response
        //get token
        const jwt = res.data.type + ' ' + res.data.token
        const newRefreshKey = res.data.refreshToken

        Cookies.set(tokenKey, jwt)
        Cookies.set(tokenRefreshKey, newRefreshKey)
        return window.location.reload(true)
    }
}

export function getCurrentUser() {
    try {
        const jwt = Cookies.get(tokenKey)

        let currentTime = new Date().getTime() / 1000

        if (currentTime > jwtDecode(jwt).exp) {
            const refreshKey = Cookies.get(tokenRefreshKey)

            if (refreshKey) {
                refreshToken()
            }
            else {
                Cookies.remove(tokenKey)
                return window.location = '/login'
            }
        }

        return jwtDecode(jwt)
    } catch(ex) {
        return null
    }
}

export function getJwt() {
    return Cookies.get(tokenKey)
}

function updateJwtAndCookies(res) {
    if (res) {
        const { data } = res.data
        // get token
        const jwt = data.type + ' ' + data.token
        const refreshKey = data.refreshToken

        Cookies.set(tokenKey, jwt)
        Cookies.set(tokenRefreshKey, refreshKey)
        http.setJwt(getJwt())

        return res
    }
}

export default {
    getJwt,
    getCurrentUser,
    login,
    logout,
    refreshToken
}