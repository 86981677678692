import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/group'

export async function getGroups(eduId) {
    return await http.get(apiEndpoint, { headers: { 'X-Edu': eduId }})
}

export default {
    getGroups
}