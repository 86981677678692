import React, { Component } from 'react'
import { Form, Icon, Input, Button } from 'antd'
import auth from 'services/authService'

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field])
}

class Login extends Component {
    componentDidMount() {
        if (auth.getCurrentUser())
            this.props.history.push('/')

        // To disable submit button at the beginning.
        this.props.form.validateFields()
    }
    
    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields( async (err, values) => {
            if (!err) {
                const res = await auth.login(values)
                
                if (res && res.status === 200) {
                    const { state } = this.props.location
                    this.props.history.push(state ? state.from.pathname : '/')
                }
            }
        })
    }
    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form

        // Only show error after a field is touched.
        const loginError = isFieldTouched('login') && getFieldError('login')
        const passwordError = isFieldTouched('password') && getFieldError('password')
        return (
            <div className="login">
                <div className="login-wrap">
                    <h1 className="login__title"><span>Edu</span>Market</h1>
                    <Form layout="inline" onSubmit={this.handleSubmit}>
                        <Form.Item validateStatus={loginError ? 'error' : ''} help={loginError || ''}>
                            {getFieldDecorator('login', {
                                rules: [{ required: true, message: 'Please input your login!' }],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', fontSize: 16 }} />}
                                    placeholder="Login"
                                    size="large"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please input your Password!' }],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)', fontSize: 16 }} />}
                                    type="password"
                                    placeholder="Password"
                                    size="large"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary"
                                size="large"
                                htmlType="submit"
                                disabled={hasErrors(getFieldsError())}>
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}

const WrappedLoginForm = Form.create()(Login)
export default WrappedLoginForm
