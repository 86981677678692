import http from './httpService'
import { apiUrl } from '../config.json'

const apiEndpoint = apiUrl + '/rating/class'

export async function getRating(group, eduId) {
    return await http.get(apiEndpoint + '/' + group, { headers: { 'X-Edu': eduId }})
}

export default {
    getRating
}